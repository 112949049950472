import React, {useState} from 'react';
import { get } from "src/utils";
import { PopupVideo } from "src/components/commonComponents";
import getTitleId from "../helpers/getTitleId";
import { schemaMarkupRendering } from '../helpers/schemaMarkupRendering';
import { Picture } from '../commonComponents/picture';
import { LocalizedLink } from '../commonComponents/localizedLink';
const getImageObject = require("src/../helpers/getImageObject")
const SimpleResourceBlock = ({ contentEntity }) => {
  const heroVideoUrl = contentEntity?.relationships?.fieldVideoRef ? get(contentEntity, "relationships.fieldVideoRef.fieldMediaVideoEmbedField") : false;
  const thumbnail = contentEntity?.relationships?.fieldVideoRef ? get(contentEntity, "relationships.fieldVideoRef.relationships.thumbnail.url") : false;
  const isLeft = (contentEntity.fieldContentFormat === 'content_left');
  const isBanner = (contentEntity.fieldContentFormat === 'content_left_background_img_with_logo');
  const [openVideoExt, setOpenVideoExt] = useState(false);
  const image = getImageObject(contentEntity?.relationships?.fieldMediaImage,{},"fieldMediaImage")
  const openVideo = (e) => {
    e.preventDefault();
    setOpenVideoExt(true);
    setTimeout(() => {
      setOpenVideoExt(false);
    }, 500);
  };
  let imageAttrs = image?.gatsbyImage?.images?.fallback
  
  if (isBanner){

    const content = (
      <div className="tw-column tw-w-full lg:tw-w-[38%] !tw-p-0 tw-z-10 !tw-flex tw-justify-end">
        {heroVideoUrl
          ? <PopupVideo videoUrl={heroVideoUrl} thumbnail={thumbnail} thumbnailClass={"!tw-p-0 simple-resource-block-video tw-relative tw-pr-0"} openVideoExt={openVideoExt} />
          :
          (!!imageAttrs?.src && <img
            {...imageAttrs}
            alt={image?.alt}
            loading="eager"
            fetchpriority="high"
            className={'knowledge-hub-image tw-w-full tw-max-w-[300px]'}
            decoding = "async"
          />)
  
        }
      </div>
    );
  
    const bgImage = getImageObject(
      contentEntity?.relationships?.fieldBackgroundImage,
      {},
      "fieldBackgroundImage"
    );

    const logo = getImageObject(contentEntity?.relationships?.fieldTeaserLogo, contentEntity?.fieldTeaserLogo)

    return (
      <div className='banner-simmple-resource-block tw-relative tw-w-full tw-h-auto tw-flex tw-p-4  lg:tw-p-6 tw-items-center lg:tw-pl-[72px] lg:tw-pr-[100px]'>
        <Picture className=" tw-absolute tw-top-0 tw-left-0 rigt tw-h-full tw-w-full bg-img tw-object-cover" image={bgImage} />
        <div className=' tw-mr-4 lg:tw-mr-8 tw-z-10'>
          <Picture className=" tw-w-[20%]" image={logo} />
          {contentEntity.title && <p className="title-5 tw-font-Poppins tw-font-bold !tw-text-[12px] lg:!tw-text-[24px] !tw-leading-[14px] lg:!tw-leading-[27.84px] !tw-mb-4 !tw-mt-[8px] !tw-text-accruent_white">{contentEntity.title}</p>}
        {contentEntity.body?.value && (
          <div className="banner-description dangerously-html-handl tw-text-white">
            {contentEntity.body?.value &&
              <div dangerouslySetInnerHTML={{
                __html: contentEntity.body?.value,
              }} ></div>}
          </div>
        )}
           <LocalizedLink className='tw-inline-block tw-font-bold tw-border-[2px] tw-cursor-pointer !tw-m-0 !tw-text-[9px] !tw-py-[8px] tw-px-[16px]  !tw-rounded-[8px] lg:!tw-rounded-[12px] lg:!tw-px-[1.5rem] lg:!tw-py-[14px]  lg:!tw-text-[16px] !tw-bg-transparent !tw-border-white !tw-text-white !tw-font-Roboto' href={contentEntity.fieldCtaUrl?.url}>
            {contentEntity.fieldCtaUrl?.title}
          </LocalizedLink>

        </div>
        {content}
        {contentEntity.fieldCtaUrl?.url && <div>
          <LocalizedLink className='tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-z-[100]' to={contentEntity.fieldCtaUrl?.url}/>
        </div>}
      </div>
    )
  }

  const content = (
    <div className="tw-column tw-w-full lg:tw-w-[33.33%] !tw-p-0">
      {heroVideoUrl
        ? <PopupVideo videoUrl={heroVideoUrl} thumbnail={thumbnail} thumbnailClass={"!tw-p-0 simple-resource-block-video tw-relative tw-pr-0"} openVideoExt={openVideoExt} />
        :
        (!!imageAttrs?.src && <img
          {...imageAttrs}
          alt={image?.alt}
          loading="eager"
          fetchpriority="high"
          className={'knowledge-hub-image'}
          decoding = "async"
        />)

      }
    </div>
  );

  return (
    <div className="tw-columns !tw-m-[-0.75rem] simple-resource-block tw-bg-[#E6E7E8] tw-shadow-[3px_3px_20px_#7D7D7D29] tw-rounded-[24px_24px_24px_0px] tw-py-[22px] tw-px-[18px]" id={getTitleId(contentEntity.title)}>
    {schemaMarkupRendering(contentEntity)}
      {isLeft && (<>{content}</>)}
      <div className="tw-column tw-w-full lg:tw-w-[66.66%]">
        <h3>{contentEntity.title}</h3>
        <div dangerouslySetInnerHTML={{ __html: contentEntity.body?.value }} className="dangerously-html-handle" />
        {!heroVideoUrl ? (
          <a className=' tw-text-[#001c71] hover:tw-text-[#001c71] tw-text-[16px] tw-leading-4 tw-font-bold tw-font-Poppins' href={contentEntity.fieldCtaUrl?.url}>
            {contentEntity.fieldCtaUrl?.title}
          </a>
        ) : (
          <a className='tw-text-[#001c71] hover:tw-text-[#001c71] tw-text-[16px] tw-leading-4 tw-font-bold tw-font-Poppins' href="#" onClick={openVideo}>
            {contentEntity.fieldCtaUrl?.title}
          </a>
        )}
      </div>
      {!isLeft && (<>{content}</>)}
    </div>
  );
}

export default SimpleResourceBlock;
